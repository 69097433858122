/**
* Generated automatically at built-time (2025-04-03T07:20:02.862Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "stainless-outdoor-kitchen",templateKey: "sites/101-800216bf-6b18-4ff7-91cb-f0ada7e2e4e3"};